.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
}
.responsive-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  /* border-radius: 10%; */
}
.responsive-table thead th {
  /* background-color: #1d96b2; */
  background: radial-gradient(
    65.49% 65.49% at 50% 50%,
    #030239 0%,
    #03014e 100%
  );
  border-bottom: 1px solid;
  font-weight: normal;
  text-align: center;
  color: white;
}
/* .responsive-table thead th:first-of-type {
  text-align: left;
} */
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: left;
  white-space: normal;
}
.responsive-table th,
.responsive-table td {
  padding: 0.5em;
  vertical-align: middle;
}
.responsive-table caption {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
.responsive-table tfoot {
  font-size: 0.8em;
  font-style: italic;
}
.responsive-table tbody tr {
  margin-bottom: 1em;
  /* border-bottom: 2px solid #dfe5ff; */
}
.responsive-table tbody tr:last-of-type {
  margin-bottom: 0;
}
.responsive-table tbody th[scope="row"] {
  background: radial-gradient(
    65.49% 65.49% at 50% 50%,
    #030239 0%,
    #03014e 100%
  );
  color: #dfe5ff;
}

/* .responsive-table tbody td[data-type="currency"] {
  text-align: center;
} */
.responsive-table tbody td[data-title]:before {
  content: attr(data-title);
  float: left;
  font-size: 0.8em;
  color: #dfe5ff;
}
.responsive-table tbody td {
  text-align: right;
  /* border-bottom: 1px solid  ; */
  background: radial-gradient(
    65.49% 65.49% at 50% 50%,
    #030239 0%,
    #03014e 100%
  );
  color: #dfe5ff;
}

@media (min-width: 52em) {
  .responsive-table {
    font-size: 0.9em;
  }
  .responsive-table thead {
    position: relative;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
  .responsive-table tr {
    display: table-row;
  }
  .responsive-table th,
  .responsive-table td {
    display: table-cell;
    padding: 0.5em;
  }

  .responsive-table caption {
    font-size: 1.5em;
  }
  .responsive-table tbody {
    display: table-row-group;
  }
  .responsive-table tbody tr {
    display: table-row;
    border-bottom: 1px;
  }

  .responsive-table tbody tr:nth-of-type(even) {
    background: radial-gradient(
      65.49% 65.49% at 50% 50%,
      #030239 0%,
      #03014e 100%
    );
    color: #dfe5ff;
  }
  .responsive-table tbody th[scope="row"] {
    /* background-color: transparent; */
    color: #dfe5ff;
    text-align: left;
    border-bottom: 1px;
  }
  .responsive-table tbody td {
    text-align: center;
    background: radial-gradient(
      65.49% 65.49% at 50% 50%,
      #030239 0%,
      #03014e 100%
    );
    color: #dfe5ff;
  }
  .responsive-table tbody td[data-title]:before {
    content: none;
  }
}
@media (min-width: 62em) {
  .responsive-table {
    font-size: 1em;
  }
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em 0.5em;
  }
  .responsive-table tfoot {
    font-size: 0.9em;
  }
}

@media (min-width: 75em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em;
  }
}

/* 
.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
}
.responsive-table thead {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.responsive-table {
  background: radial-gradient(
    65.49% 65.49% at 50% 50%,
    #030239 0%,
    #03014e 100%
  );
  border-radius: 50px !important;
  font-weight: normal;
  text-align: center;
  color: white;
}
.responsive-table thead th:first-of-type {
  text-align: center;
}
.responsive-table tbody,
.responsive-table tr,
.responsive-table th,
.responsive-table td {
  display: block;
  padding: 0;
  text-align: center;
  white-space: normal;
}
.responsive-table th,
.responsive-table td {
  padding: 1em;
  vertical-align: middle;
}
.responsive-table caption {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
.responsive-table tfoot {
  font-size: 0.8em;
  font-style: italic;
}
.responsive-table tbody tr {
  margin-bottom: 1em;
  
}
.responsive-table tbody tr:last-of-type {
  margin-bottom: 0;
}
.responsive-table tbody th[scope="row"] {
  background: radial-gradient(
    65.49% 65.49% at 50% 50%,
    #030239 0%,
    #03014e 100%
  );
  color: white;
}
.responsive-table tbody td[data-type="currency"] {
  text-align: center;
}
.responsive-table tbody td[data-title]:before {
  content: attr(data-title);
  float: left;
  font-size: 0.8em;
  color: #dfe5ff;
}
.responsive-table tbody td {
  text-align: right;

}

@media (min-width: 52em) {
  .responsive-table {
    font-size: 0.9em;
  }
  .responsive-table thead {
    position: relative;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
  .responsive-table tr {
    display: table-row;
  }
  .responsive-table th,
  .responsive-table td {
    display: table-cell;
    padding: 0.5em;
  }

  .responsive-table caption {
    font-size: 1.5em;
  }
  .responsive-table tbody {
    display: table-row-group;
  }
  .responsive-table tbody tr {
    display: table-row;

  }
  .responsive-table tbody tr:nth-of-type(even) {
    background: radial-gradient(
      65.49% 65.49% at 50% 50%,
      #030239 0%,
      #03014e 100%
    );
    color: #dfe5ff;
  }
  .responsive-table tbody th[scope="row"] {
    color: #dfe5ff;
    text-align: center;
  }
  .responsive-table tbody td {
    text-align: center;

  }
  .responsive-table tbody td[data-title]:before {
    content: none;
  }
}
@media (min-width: 62em) {
  .responsive-table {
    font-size: 1em;
  }
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em 0.5em;
  }
  .responsive-table tfoot {
    font-size: 0.9em;
  }
}

@media (min-width: 75em) {
  .responsive-table th,
  .responsive-table td {
    padding: 0.75em;
  }
} */
